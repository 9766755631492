import '../styles/globals.css';
import '../styles/review.scss';
import HotKeyProvider from 'providers/hotKeyProvider';
import ToastProvider from 'providers/toastProvider';
import SearchProvider from 'providers/searchProvider';
import FormProvider from 'providers/formProvider';
import {ApolloProvider} from '@apollo/client';
import {client} from 'utils/apollo';
import {AppPropsWithLayout} from 'types';
import 'tailwind-config/global.css';
import {WrappedPostHogProvider} from 'components/posthog';
import {FullStoryProvider} from 'components/fullstory';

const MyApp: React.FC<AppPropsWithLayout> = ({Component, pageProps}) => {
  const getLayout = Component.getLayout || ((page) => page);

  return (
    <ApolloProvider client={client}>
      <WrappedPostHogProvider>
        <FullStoryProvider>
          <FormProvider>
            <HotKeyProvider>
              <SearchProvider>
                <ToastProvider>
                  {/*
                   * Attempt to inject page-level layouts if present.
                   * Otherwise, just render the page component as is.
                   */}
                  {getLayout(<Component {...pageProps} />, pageProps)}
                </ToastProvider>
              </SearchProvider>
            </HotKeyProvider>
          </FormProvider>
        </FullStoryProvider>
      </WrappedPostHogProvider>
    </ApolloProvider>
  );
};

export default MyApp;
