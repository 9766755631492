import {ApolloCache, ApolloError, FetchResult} from '@apollo/client';
import {WatchQueryFetchPolicy} from '@apollo/client';
import {useQuery} from 'hooks/useQuery';
import {
  getLoggedInUser as GetLoggedInUserQuery,
  GetLoggedInUserData,
} from 'queries/user';
import {User} from 'types';

const useSessionUser = (
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first',
): [User | null, boolean, ApolloError?] => {
  const {data, loading, error} = useQuery<GetLoggedInUserData>(
    GetLoggedInUserQuery,
    {
      fetchPolicy,
    },
  );

  if (error) {
    console.error(`error getting session user`, error);
    return [null, loading, error];
  }

  return [data?.ownUser, loading, error];
};

export const updateSessionUser = (
  cache: ApolloCache<any>,
  result: FetchResult,
) => {
  const user =
    result?.data?.user ??
    result?.data?.logIn?.user ??
    result?.data?.signUp?.user;

  if (!user) {
    return;
  }

  cache.writeQuery({
    query: GetLoggedInUserQuery,
    data: {
      ownUser: user,
    },
  });
};

export default useSessionUser;
