import gql from 'graphql-tag';
import {BASE_ADDRESS_FIELDS} from './address';
import {BASE_TERMS_DOCUMENT_FIELDS} from './termsDocument';

export const ACCEPTED_PAYMENT_METHODS_CONFIG_FIELDS = gql`
  fragment AcceptedPaymentMethodsConfigFields on AcceptedPaymentMethodsConfigNode {
    id
    paywallCreditCard
    paywallAch
    paywallCheck
    checkoutCreditCard
    checkoutAch
    checkoutOrderForm
    minimumOrderFormTcv
  }
`;

export const BASE_FEATURE_GATE_FIELDS = gql`
  fragment BaseFeatureGateFields on FeatureGateNode {
    featureCode
    enabled
    adminOnly
    beta
  }
`;

export const BASE_SELLER_FIELDS = gql`
  ${BASE_ADDRESS_FIELDS}
  ${BASE_TERMS_DOCUMENT_FIELDS}
  fragment BaseSellerFields on SellerNode {
    id
    legalName
    name
    description
    sellerType
    termsDocument {
      ...BaseTermsDocumentFields
    }
    commercialTermsDocuments {
      ...BaseTermsDocumentFields
    }
    w9 {
      filename
      url
    }
    privacyPolicy
    contactDetails {
      id
      phone
      email
    }
    accountsReceivableEmail
    accountsPayableEmail
    forceStandardBillingSchedule
    paymentDueUponReceipt
    salesOperationsEmail
    salesEmail
    website
    logoImage
    logoImageDark
    primaryColor
    customerSuccess {
      id
      phone
      email
    }
    hq {
      ...BaseAddressFields
    }
    nexusAddresses {
      id
      line1
      line2
      city
      region
      country
      zip
    }
    checkAddress {
      ...BaseAddressFields
    }
    isBankLockbox
    productSet {
      id
      name
      slug
    }
    onboardingComplete
    supportedCurrencies
    banks {
      id
      currency
      name
      swiftCode
      routingNumber
      accountNumber
      region
      iban
    }
    showMsaDuringCheckout
    showCommercialTermsDuringCheckout
    customInvoiceText
    customInternationalInvoiceText
  }
`;
