export interface FormReducerState {
  [nameSpace: string]: {
    values: {[key: string]: any};
    errors: {[key: string]: string};
  };
}

type FormReducerAction =
  | UpdateValueAction
  | UpdateErrorAction
  | SetNameSpaceValuesAction
  | ClearNameSpaceAction
  | SetRootValuesAction;

interface UpdateValueAction {
  type: 'updateValue';
  payload: {
    nameSpace: string;
    key: string;
    value: any;
  };
}

interface UpdateErrorAction {
  type: 'updateError';
  payload: {
    nameSpace: string;
    key: string;
    error: string;
  };
}

interface SetNameSpaceValuesAction {
  type: 'setNameSpaceValues';
  payload: {
    nameSpace: string;
    values: {[key: string]: any};
  };
}

interface SetRootValuesAction {
  type: 'setRootValues';
  payload: FormReducerState;
}

interface ClearNameSpaceAction {
  type: 'clearNameSpace';
  payload: string;
}

const FormReducer = (state: FormReducerState, action: FormReducerAction) => {
  if (action.type === 'updateError') {
    const {nameSpace, key, error} = action.payload;
    let newState = {...state};
    if (!newState[nameSpace]) {
      newState[nameSpace] = {values: {}, errors: {}};
    }
    const {errors} = newState[nameSpace];
    newState[nameSpace].errors = {
      ...errors,
      [key]: error,
    };
    return newState;
  }
  if (action.type === 'updateValue') {
    const {nameSpace, key, value} = action.payload;
    let newState = {...state};
    if (!newState[nameSpace]) {
      newState[nameSpace] = {values: {}, errors: {}};
    }
    const {values} = newState[nameSpace];
    newState[nameSpace].values = {
      ...values,
      [key]: value,
    };
    return newState;
  }
  if (action.type === 'setNameSpaceValues') {
    const {nameSpace, values} = action.payload;
    return {...state, [nameSpace]: {values, errors: {}}};
  }
  if (action.type === 'clearNameSpace') {
    const newState = {...state};
    newState[action.payload] = undefined;
    return newState;
  }

  if (action.type === 'setRootValues') {
    return {...action.payload};
  }

  return state;
};

export default FormReducer;
