import gql from 'graphql-tag';

export const BASE_TERMS_DOCUMENT_FIELDS = gql`
  fragment BaseTermsDocumentFields on TermsDocumentNode {
    id
    name
    redlined
    renewalTerms
    documentType
    createdAt
    parent {
      id
    }
  }
`;
