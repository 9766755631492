import gql from 'graphql-tag';

export const BASE_FINANCE_SYSTEM_INTEGRATION_FIELDS = gql`
  fragment BaseFinanceSystemIntegrationFields on FinanceSystemIntegrationNode {
    id
    active
    token
    connectionId
    financeSystemType
  }
`;
