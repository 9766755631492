import {
  useQuery as useApolloQuery,
  useMutation as useApolloMutation,
  useLazyQuery as useApolloLazyQuery,
  DocumentNode,
  QueryHookOptions,
  LazyQueryHookOptions,
  MutationHookOptions,
  FetchResult,
  MutationFunctionOptions,
  ApolloError,
  OperationVariables,
  ApolloQueryResult,
} from '@apollo/client';
import {useEffect, useState} from 'react';
import {BrickPricing, Currency} from 'types';
import {parseGraphqlDate, parseGraphqlDatetime} from 'utils/dateUtils';

// a list of know date and datetime fields that we can use to translate. ideally we would
// use a middleware or type to do this automatically
const dateFields = [
  'endsAt',
  'lastTerminatedAt',
  'endDate',
  'startsAt',
  'dueAt',
  'terminatedAt',
  'calculatedAt',
  'runCalculateAt',
];

const dateTimeFields = [
  'paidAt',
  'expiresAt',
  'createdAt',
  'updatedAt',
  'lastSuccessfulTestAt',
  'deletedAt',
  'publishedAt',
  'lastTermDate',
  'originalTimestamp',
  'latestTimestamp',
  'generatedAt',
  'invoiceDate',
  'dueDate',
  'paymentDate',
  'lastCheckedAt',
];

const decimalFields = ['discountingPower', 'salesRate', 'oneTimeRate'];

export const useQuery = <
  T,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode,
  options?: QueryHookOptions<T, TVariables> & {loadingTimeoutMS?: number},
): {
  data: T;
  error?: ApolloError;
  loading?: boolean;
  firstLoad?: boolean;
  refetch: (variables?: Partial<TVariables>) => Promise<ApolloQueryResult<T>>;
} => {
  //TODO: change wloading to be a timestamp, use the time from when loading starts
  //then subtract elapsed time when loading is finished to only wait for
  //remainder of max(loadingTimeoutMS - elapsedTime, 0)
  const {data, error, loading, refetch} = useApolloQuery(query, options);
  // default w(rapped) loading to be whatever is returned from useApolloQuery as we might
  // already have data
  const [wloading, setWLoading] = useState(loading);

  useEffect(() => {
    if (loading == wloading) {
      // no change
      return;
    }

    if (loading && !wloading) {
      // loading has just started
      setWLoading(true);
      return;
    }

    // loading is finished
    if (options?.loadingTimeoutMS ?? 0) {
      setTimeout(() => {
        setWLoading(false);
      }, options.loadingTimeoutMS);
    } else {
      setWLoading(false);
    }
  }, [loading, wloading, setWLoading]);

  // if we are either wloading or loading, we need to use it
  // as setWLoading can miss renders leading us to leak a loading false
  // when in fact we are still loading
  const isLoading = loading || wloading;

  if (data) {
    const newData = JSON.parse(JSON.stringify(data));
    formatRawGraphQLResult(newData);
    return {data: newData, error, loading: isLoading, refetch};
  }

  return {
    data,
    error,
    loading: isLoading,
    refetch,
  };
};

export const useLazyQuery = <T,>(
  query: DocumentNode,
  options?: QueryHookOptions,
): [
  (options?: LazyQueryHookOptions) => Promise<{data: T}>,
  {data: T; error?: ApolloError; loading?: boolean},
] => {
  const [callback, {data, error, loading}] = useApolloLazyQuery(query, options);

  if (data) {
    const newData = JSON.parse(JSON.stringify(data));
    formatRawGraphQLResult(newData);
    return [callback, {data: newData, error, loading}];
  }

  return [
    callback,
    {
      data,
      error,
      loading,
    },
  ];
};

// This is probably still missing the error responses?
export const useMutation = <T,>(
  query: DocumentNode,
  options?: MutationHookOptions,
): [
  (options?: MutationHookOptions) => Promise<FetchResult<T, any, any>>,
  {data: T; error?: ApolloError; loading?: boolean},
] => {
  const [callback, {data, error, loading}] = useApolloMutation<T, any>(
    query,
    options,
  );

  if (data) {
    const newData = JSON.parse(JSON.stringify(data));
    formatRawGraphQLResult(newData);
    return [callback, {data: newData, error, loading}];
  }

  const modifiedCallback = async (
    options?: MutationFunctionOptions,
  ): Promise<FetchResult<T>> => {
    const {data} = await callback(options);
    // add check here so that getting `undefined` doesn't
    // crash the app.
    // (`data` is undefined if `error` is returned from query)
    const newData = data ? JSON.parse(JSON.stringify(data)) : data;
    formatRawGraphQLResult(newData);
    return {data: newData};
  };

  return [
    modifiedCallback,
    {
      data,
      error,
      loading,
    },
  ];
};

export function formatRawGraphQLResult(inputObject: any) {
  if (!inputObject) {
    return;
  }

  if (inputObject['__typename']) {
    // we want to manually parse BrickPricingCurrency in to another format
    if (inputObject['__typename'] === 'BrickPricingNode') {
      // parse in to a Record<Currency, BrickPricingTier[]>
      if ('tiers' in inputObject) {
        inputObject['tiers'] = inputObject['tiers'].reduce(
          (
            tiers: Record<Currency, BrickPricing[]>,
            i: {currency: Currency; tiers: BrickPricing[]},
          ) => {
            tiers[i.currency] = i.tiers;
            return tiers;
          },
          {},
        );
      }
    }
    delete inputObject['__typename'];
  }

  decimalFields.forEach((k: string) => {
    if (inputObject[k]) {
      inputObject[k] = parseFloat(inputObject[k]);
    }
  });

  dateFields.forEach((k: string) => {
    if (inputObject[k]) {
      inputObject[k] = parseGraphqlDate(inputObject[k]);
    }
  });

  dateTimeFields.forEach((k: string) => {
    if (inputObject[k]) {
      inputObject[k] = parseGraphqlDatetime(inputObject[k]);
    }
  });

  if (inputObject['metadata']) {
    if (typeof inputObject['metadata'] === 'string') {
      inputObject['metadata'] = JSON.parse(inputObject['metadata']);
    }
  }

  if (Array.isArray(inputObject)) {
    inputObject.forEach(formatRawGraphQLResult);
  } else {
    for (const key in inputObject) {
      if (
        key &&
        key.toLowerCase().includes('daterange') &&
        Array.isArray(inputObject[key])
      ) {
        // check if the object is a date range array, and convert items accordingly
        inputObject[key].forEach((k: string, i, a) => {
          a[i] = parseGraphqlDate(k);
        });
      }
      if (typeof inputObject[key] === 'object' && inputObject[key] !== null) {
        formatRawGraphQLResult(inputObject[key]);
      }
    }
  }
}
