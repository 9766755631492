import gql from 'graphql-tag';

export const BASE_USER_FIELDS = gql`
  fragment BaseUserFields on UserNode {
    id
    email
    firstName
    lastName
  }
`;
