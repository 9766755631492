import gql from 'graphql-tag';

export const BASE_EMPLOYMENT_FIELDS = gql`
  fragment BaseEmploymentFields on EmploymentNode {
    id
    role {
      id
      role
    }
    sellerOrderFormSigner
    isCompanyAdmin
    discountingPower
    showWorkspaceWarning
  }
`;
