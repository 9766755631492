import React, {useContext, useReducer, useEffect} from 'react';
import {useRouter} from 'next/router';

interface Funcs {
  setSearchTerm: (term: string) => void;
  getQueryParams: () => string;
  updateSubmitHash: () => void;
}

export interface SearchProviderProps extends Funcs {
  term: string | null;
  tags: string[];
  submitHash: string;
}

const INITIAL_SUBMIT_HASH = new Date().getTime() + '';

export const SearchContext = React.createContext<Partial<SearchProviderProps>>({
  term: '',
  tags: [],
  submitHash: INITIAL_SUBMIT_HASH,
});

type SearchAction = TermAction | TagAction | UpdateSubmitHashAction;

interface UpdateSubmitHashAction {
  type: 'updateSubmitHash';
  payload: null;
}

interface TermAction {
  type: 'clearTerm' | 'setTerm';
  payload: string;
}

interface TagAction {
  type: 'clearTag' | 'setTag' | 'addTag' | 'removeTag';
  payload: string;
}

interface SearchReducerState {
  tags: string[];
  term: string | null;
}

const SearchTermReducer = (state: SearchReducerState, action: SearchAction) => {
  const {type, payload} = action;

  if (type === 'updateSubmitHash') {
    return {...state, submitHash: new Date().getTime() + ''};
  }
  if (type === 'setTerm') {
    return {...state, term: payload};
  }
  if (type === 'clearTerm') {
    return {...state, term: null};
  }
  return state;
};

const INITIAL_SEARCH_TERM_REDUCER_STATE = {
  tags: [],
  term: null,
  submitHash: new Date().getTime() + '',
};

const SearchProvider = (props: {children: React.ReactNode}) => {
  const [state, dispatch] = useReducer(
    SearchTermReducer,
    INITIAL_SEARCH_TERM_REDUCER_STATE,
  );

  const router = useRouter();

  // updating Submit Hashes helps give the UI a hint as to when to make
  // a new request
  useEffect(() => {
    const {search: searchTerm} = router.query;
    if (searchTerm) {
      dispatch({type: 'setTerm', payload: searchTerm as string});
      dispatch({type: 'updateSubmitHash', payload: null});
    }
  }, [router.query]);

  const value = {
    ...state,
    clearSearchTerm: () => {
      dispatch({type: 'clearTerm', payload: ''});
    },
    setSearchTerm: (newTerm: string) => {
      dispatch({type: 'setTerm', payload: newTerm});
    },
    getQueryParams: () => {
      const params = new URLSearchParams();
      params.append('search', state.term);
      return params.toString();
    },
    updateSubmitHash: () => {
      dispatch({type: 'updateSubmitHash', payload: null});
    },
  };

  return <SearchContext.Provider value={value} {...props} />;
};

export const useSearchContext = (): SearchProviderProps => {
  const searchContext = useContext(SearchContext);

  if (searchContext === undefined) {
    throw new Error(
      'Attempting to read SearchContext outside a Provider heirarchy',
    );
  }

  return searchContext as SearchProviderProps;
};

export default SearchProvider;
