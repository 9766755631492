import {PropsWithChildren, useEffect, useState} from 'react';
import {init as initFullStory, FullStory} from '@fullstory/browser';
import useSessionUser from 'hooks/useSessionUser';

interface FullStoryProviderProps extends PropsWithChildren {}

export const FullStoryProvider = ({children}: FullStoryProviderProps) => {
  const orgId = process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID;
  const shouldUseFullstory = !!orgId && orgId.length > 0;

  const [user] = useSessionUser();
  const [initalized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (!shouldUseFullstory) return;
    initFullStory({orgId: orgId});
    setInitialized(true);
  }, [shouldUseFullstory, orgId]);

  useEffect(() => {
    if (!shouldUseFullstory || !user || !initalized) return;
    FullStory('setIdentity', {uid: user.id, properties: {...user}});
  }, [shouldUseFullstory, user?.id, initalized]);

  return <>{children}</>;
};
