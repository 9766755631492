var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"v6VnN92bcBvq0Ni5j5LIt"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the intialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import {CaptureConsole} from '@sentry/integrations';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

window.Sentry = Sentry;

window.Sentry.init({
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  replaysOnErrorSampleRate: 1.0,
  dsn:
    SENTRY_DSN ||
    'https://3e4bd9eb1e914fac93063384ecc9a45a@o575065.ingest.sentry.io/5751398',
  environment: process.env.NEXT_PUBLIC_ENV,
  integrations: [
    new Sentry.BrowserTracing(),
    new CaptureConsole({
      levels: ['error'],
    }),
    new Sentry.Replay({
      maskAllText: false
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    'https://app.salesbricks.com',
    'https://app.staging.salesbricks.com',
    'https://app.main.salesbricks.com',
    'https://api.salesbricks.com',
    'https://api.staging.salesbricks.com',
    'https://api.main.salesbricks.com',
  ],
});
