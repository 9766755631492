import gql from 'graphql-tag';

export const BASE_FEATURE_FIELDS = gql`
  fragment BaseFeatureFields on FeatureNode {
    id
    alive
    active
    name
    summary
  }
`;
