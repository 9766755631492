import gql from 'graphql-tag';
import {
  ConversightRole,
  CrmIntegration,
  CrmIntegrationType,
  EmploymentRole,
  User,
} from 'types';
import {
  ACCEPTED_PAYMENT_METHODS_CONFIG_FIELDS,
  BASE_SELLER_FIELDS,
} from 'fragments/seller';
import {BASE_EMPLOYMENT_FIELDS} from 'fragments/employment';
import {BASE_ADDRESS_FIELDS} from 'fragments/address';
import {BASE_ORDER_BRICK_FIELDS} from 'fragments/order';
import {BASE_FINANCE_SYSTEM_INTEGRATION_FIELDS} from 'fragments/financeSystemIntegration';
import {BASE_TERMS_DOCUMENT_FIELDS} from 'fragments/termsDocument';

export interface GetUserData {
  user: User;
}

export const getUser = gql`
  ${BASE_EMPLOYMENT_FIELDS}
  query getUser($id: String!) {
    user(id: $id) {
      id
      firstName
      lastName
      alive
      email
      employment {
        id
        ...BaseEmploymentFields
      }
    }
  }
`;

export interface GetLoggedInUserData {
  ownUser: User;
}

export const LOGGED_IN_USER_FIELDS = gql`
  ${BASE_EMPLOYMENT_FIELDS}
  ${BASE_ADDRESS_FIELDS}
  ${BASE_FINANCE_SYSTEM_INTEGRATION_FIELDS}
  ${BASE_TERMS_DOCUMENT_FIELDS}
  fragment LoggedInUserFields on UserNode {
    id
    firstName
    lastName
    alive
    email
    hasCrmConnection
    employment {
      id
      ...BaseEmploymentFields
      company {
        id
        name
        sellerAccount {
          id
          name
          legalName
          hq {
            ...BaseAddressFields
          }
          primaryColor
          onboardingComplete
          showMsaDuringCheckout
          showCommercialTermsDuringCheckout
          salesbricksSubscriptionId
          supportedCurrencies
          usageLastUploaded
          fiscalYearStartMonth
          crmIntegrations {
            crmType
            revenueScheduleSyncEnabled
            productSyncEnabled
          }
          sellerType
          financeSystemIntegrations {
            ...BaseFinanceSystemIntegrationFields
          }
          termsDocument {
            ...BaseTermsDocumentFields
          }
          commercialTermsDocuments {
            ...BaseTermsDocumentFields
          }
          forceStandardBillingSchedule
        }
      }
    }
  }
`;

export const getLoggedInUser = gql`
  ${LOGGED_IN_USER_FIELDS}
  query getLoggedInUser {
    ownUser {
      ...LoggedInUserFields
    }
  }
`;

export interface GetOwnUserData {
  ownUser: User;
}

export const getOwnUser = gql`
  ${BASE_EMPLOYMENT_FIELDS}
  ${BASE_SELLER_FIELDS}
  ${BASE_ORDER_BRICK_FIELDS}
  query getOwnUser {
    ownUser {
      id
      firstName
      lastName
      email
      alive
      orderSet {
        id
        stage
        startsAt
        endsAt
        billingSchedule
        documentsGeneratedAt
        closedAt
        createdAt
        updatedAt
        lineItems {
          ...BaseOrderBrickFields
        }
      }
      employment {
        ...BaseEmploymentFields
        company {
          id
          sellerAccount {
            id
            ...BaseSellerFields
          }
        }
      }
    }
  }
`;

export interface GetOwnUserCompanyData {
  ownUser: User;
}

export const getOwnUserCompany = gql`
  ${BASE_EMPLOYMENT_FIELDS}
  ${BASE_SELLER_FIELDS}
  query getOwnUserCompany {
    ownUser {
      id
      employment {
        id
        ...BaseEmploymentFields
        company {
          id
          name
          sellerAccount {
            id
            ...BaseSellerFields
          }
          documentSigner {
            id
            firstName
            lastName
          }
          employmentSet {
            id
            ...BaseEmploymentFields
            notificationPreferences {
              orderFormSent
              orderFormSigned
              id
              email
            }
            user {
              id
              alive
              firstName
              lastName
              email
              createdAt
              employment {
                id
                ...BaseEmploymentFields
              }
            }
          }
        }
      }
    }
  }
`;

export interface GetOwnUserCompanyIntegrationsData {
  ownUser: User;
}

export const getOwnUserCompanyIntegrations = gql`
  ${BASE_SELLER_FIELDS}
  ${ACCEPTED_PAYMENT_METHODS_CONFIG_FIELDS}
  query getOwnUserCompany {
    ownUser {
      employment {
        id
        company {
          id
          name
          sellerAccount {
            ...BaseSellerFields
            acceptedPaymentMethodsConfig {
              ...AcceptedPaymentMethodsConfigFields
            }
            stripeIntegration {
              id
              accountId
              isEnabled
              accountLinkUrl
              createdAt
              updatedAt
            }
            slackIntegration {
              id
              isActive
            }
          }
        }
      }
    }
  }
`;

export interface GetOwnUserSellerSubscriptionIdData {
  ownUser: User;
}

export const getOwnUserSellerSubscriptionId = gql`
  query getOwnUserCompany {
    ownUser {
      employment {
        id
        company {
          id
          sellerAccount {
            id
            salesbricksSubscriptionId
          }
        }
      }
    }
  }
`;

export interface GetCrmIntegrationData {
  crmIntegration: CrmIntegration;
}

export const getCrmIntegration = gql`
  query getCRMIntegration($crmType: String!) {
    crmIntegration(crmType: $crmType) {
      id
      crmType
      domain
      isEnabled
      oauthUrl
      isSandbox
      revenueScheduleSyncEnabled
      productSyncEnabled
      config {
        objectSyncConfigs {
          salesbricksObjectType
          crmObjectType
          salesbricksObjectFieldName
          crmObjectFieldType
          crmObjectFieldName
          enumMapping {
            salesbricksValue
            crmValue
          }
        }
      }
    }
  }
`;

export const getCrmIntegrations = gql`
  query getCRMIntegrations($isEnabled: Boolean) {
    crmIntegration(isEnabled: $isEnabled) {
      id
      crmType
    }
  }
`;

export interface CrmIntegrationsData {
  crmIntegration: {
    id: CrmIntegration['id'];
    crmType: CrmIntegrationType;
  };
}

export interface GetEmploymentRoleData {
  roles: EmploymentRole[];
}

export const getEmploymentRoles = gql`
  query getRoles {
    roles {
      id
      role
    }
  }
`;

export interface ConversightRoleData {
  conversightRole: ConversightRole;
}

export const getConversightRole = gql`
  query getConversightRole {
    conversightRole {
      token
      datasetId
    }
  }
`;
