import React, {PropsWithChildren, useEffect, useMemo} from 'react';
import {PostHogProvider} from 'posthog-js/react';
import {useRouter} from 'next/router';
import useSessionUser from 'hooks/useSessionUser';
import posthog from 'posthog-js';

interface WrappedPostHogProviderProps extends PropsWithChildren {}

function useShouldLoadPostHog(): boolean {
  // Check that PostHog is client-side (used to handle Next.js SSR)
  if (typeof window === 'undefined') {
    return false;
  }
  if (
    !process.env.NEXT_PUBLIC_POSTHOG_KEY ||
    process.env.NEXT_PUBLIC_POSTHOG_KEY.length === 0
  ) {
    return false;
  }

  return true;
}

export const ConfiguredPostHogProvider = ({
  children,
}: WrappedPostHogProviderProps) => {
  const router = useRouter();
  const [sessionUser] = useSessionUser();

  const client = useMemo(() => {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
      api_host:
        process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://us.i.posthog.com',
      person_profiles: 'identified_only',
      // Enable debug mode in development
      loaded: (posthog) => {
        if (process.env.NODE_ENV === 'development') posthog.debug();
      },
    });

    // return the initalized library as a client
    return posthog;
  }, []);

  useEffect(() => {
    if (!client) {
      return;
    }

    // Track page views
    const handleRouteChange = () => client?.capture('$pageview');
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [client]);

  useEffect(() => {
    if (!client || !sessionUser) {
      return;
    }
    posthog.identify(sessionUser.id, {...sessionUser});
  }, [client, sessionUser]);

  // no client, just return the entire children
  if (!client) return <>{children}</>;

  return <PostHogProvider client={client}>{children}</PostHogProvider>;
};

export const WrappedPostHogProvider = (props: WrappedPostHogProviderProps) => {
  const shouldLoadPostHog = useShouldLoadPostHog();

  if (!shouldLoadPostHog) {
    return <>{props.children}</>;
  }

  return <ConfiguredPostHogProvider {...props} />;
};
