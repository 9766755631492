import gql from 'graphql-tag';

export const BASE_FAQ_FIELDS = gql`
  fragment BaseFAQFields on FAQNode {
    id
    createdAt
    updatedAt
    question
    answer
    active
  }
`;
