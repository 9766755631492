import gql from 'graphql-tag';
import {
  BASE_BRICK_FIELDS,
  BASE_BRICK_PRICING_FIELDS,
  BASE_PLAN_FIELDS,
  BASE_TRIAL_SETTINGS_FIELDS,
  FULL_BRICK_CONFIG_FIELDS,
} from './product';
import {BASE_USER_FIELDS} from './user';
import {BASE_SELLER_FIELDS} from './seller';
import {BASE_ADDRESS_FIELDS} from './address';
import {BASE_PERSON_FIELDS} from './person';
import {BASE_TERMS_DOCUMENT_FIELDS} from './termsDocument';

export const BASE_ORDER_BRICK_FIELDS = gql`
  ${BASE_BRICK_PRICING_FIELDS}
  ${BASE_PLAN_FIELDS}
  ${FULL_BRICK_CONFIG_FIELDS}
  ${BASE_BRICK_FIELDS}
  ${BASE_TRIAL_SETTINGS_FIELDS}
  fragment BaseOrderBrickFields on OrderBrickNode {
    id
    planVersion {
      id
      plan {
        ...BasePlanFields
        product {
          id
          name
          slug
          isStripeIntegrationEnabled
          acceptsCreditCardPayment
          acceptsAchPayment
        }
      }
      planBrick {
        ...BaseBrickPricingFields
      }
      includedBricks {
        ...BaseBrickPricingFields
      }
      deferredBricks {
        ...BaseBrickFields
      }
      addonBricks {
        ...BaseBrickPricingFields
      }
      deferredBricks {
        ...BaseBrickFields
      }
      brickConfig {
        ...FullBrickConfigFields
      }
      trialSettings {
        ...BaseTrialSettingsFields
      }
    }
    brickPricing {
      ...BaseBrickPricingFields
    }
    quantity
    preCommitment
    overageMethod
    overageValue
  }
`;

export const BASE_ORDER_FIELDS = gql`
  ${BASE_ADDRESS_FIELDS}
  ${BASE_ORDER_BRICK_FIELDS}
  ${BASE_USER_FIELDS}
  ${BASE_PERSON_FIELDS}
  ${BASE_SELLER_FIELDS}
  ${BASE_TERMS_DOCUMENT_FIELDS}
  fragment BaseOrderFields on OrderNode {
    id
    code
    subscriptionId
    agreementNumber
    owner {
      ...BaseUserFields
    }
    signedOrderForm
    locked
    paymentDueUponReceipt
    alive
    startsAt
    endsAt
    createdAt
    updatedAt
    closedAt
    currency
    billingSchedule
    createdAt
    stage
    orderType
    terms
    termsDocument {
      ...BaseTermsDocumentFields
    }
    terminatedAt
    commercialTermsDocument {
      ...BaseTermsDocumentFields
    }
    termsAtClose {
      id
      text
    }
    primaryUser {
      ...BasePersonFields
    }
    signatoryUser {
      ...BasePersonFields
    }
    sellerSigner {
      ...BaseUserFields
      employment {
        id
        role {
          id
          role
        }
      }
    }
    seller {
      ...BaseSellerFields
      stripeAccountId
    }
    buyer {
      name
      id
      address {
        ...BaseAddressFields
      }
    }
    description
    transactionMethod
    lineItems {
      ...BaseOrderBrickFields
    }
    discount {
      minimumSpend
      oneTimeRate
      oneTimeFixed
      salesRate
      expiresAt
      isExpired
      lineItems {
        oneTimeRate
        oneTimeFixed
        salesRate
        brickId
      }
      changeCreditsReason
      planCredits
    }
    accountsPayableContacts {
      email
    }
    statementOfWork
    showMsaDuringCheckout
    showCommercialTermsDuringCheckout
    rampingConfig {
      brickPricingId
      brickId
      quantity
      startsAt
    }
    autoRenews
    crmAccount {
      crmId
    }
    crmOpportunity {
      crmId
    }
    forceStandardBillingSchedule
  }
`;

export const BASE_TRIAL_FIELDS = gql`
  fragment BaseTrialFields on TrialNode {
    id
    subscriptionId
    stage
    startsAt
    endsAt
    terminatedAt
    entitlements {
      id
      name
      quantity
    }
    buyer {
      id
      name
    }
    primaryUser {
      id
      email
      firstName
      lastName
    }
    metadata
  }
`;
