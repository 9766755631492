import React, {useContext, useEffect, useReducer} from 'react';
import FormReducer, {FormReducerState} from 'reducers/formReducer';

interface Funcs {
  updateValue: (nameSpace: string, key: string, value: any) => void;
  updateError: (nameSpace: string, key: string, error: string) => void;
  clearNameSpace: (nameSpace: string) => void;
}

export interface FormProviderProps extends Funcs {
  state: FormReducerState;
}

// FORM_SUBMITTED is a formKey conventionally used to store whether an attempt
// has been made to submit a form
export const FORM_SUBMITTED = 'FORM_SUBMITTED';

export const FormContext = React.createContext<Partial<FormProviderProps>>({});

export function getArchivedFormValues(restoreKey: string): any {
  const initialFormValuesString = window.localStorage.getItem(restoreKey);
  if (initialFormValuesString) {
    return JSON.parse(initialFormValuesString);
  }
}

export function clearArchivedFormValues(restoreKey: string): any {
  window.localStorage.removeItem(restoreKey);
}

const FormProvider = (props: {
  restoreKey?: string;
  children: React.ReactNode;
}) => {
  const {restoreKey} = props;
  const [state, dispatch] = useReducer(FormReducer, {});

  useEffect(() => {
    if (restoreKey) {
      const initialFormValues = getArchivedFormValues(restoreKey);
      if (initialFormValues && Object.keys(initialFormValues).length > 0) {
        dispatch({type: 'setRootValues', payload: initialFormValues});
      }
    }
  }, []);

  useEffect(() => {
    if (restoreKey) {
      window.localStorage.setItem(restoreKey, JSON.stringify(state));
    }
  }, [restoreKey, state]);

  const value: FormProviderProps = {
    state,
    updateError: (nameSpace: string, key: string, error: string) => {
      dispatch({
        type: 'updateError',
        payload: {
          nameSpace,
          key,
          error,
        },
      });
    },
    updateValue: (nameSpace: string, key: string, value: any) => {
      dispatch({
        type: 'updateValue',
        payload: {
          nameSpace,
          key,
          value,
        },
      });
    },
    clearNameSpace: (nameSpace: string) => {
      dispatch({type: 'clearNameSpace', payload: nameSpace});
    },
  };

  return <FormContext.Provider value={value} {...props} />;
};

export const useFormContext = (): FormProviderProps => {
  const formContext = useContext(FormContext);

  if (formContext === undefined) {
    throw new Error(
      'Attempting to read FormContext outside a Provider heirarchy',
    );
  }

  return formContext as FormProviderProps;
};

export default FormProvider;
