import gql from 'graphql-tag';

export const BASE_PERSON_FIELDS = gql`
  fragment BasePersonFields on PersonNode {
    id
    email
    firstName
    lastName
    preferredName
    company {
      id
      name
    }
    role {
      id
      role
    }
  }
`;
