import {
  ApolloClient,
  ApolloLink,
  DefaultOptions,
  InMemoryCache,
} from '@apollo/client';
import {createUploadLink} from 'apollo-upload-client';

export const PRIVATE_API = '/papi/v1/graphql';
export const PUBLIC_API = '/api/v1/graphql';

const isSSR = typeof window === 'undefined';
// ssr shouldn't cache to avoid cross pollination
const ssrOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

// link (middleware) for removing __typename
export const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables && !operation.variables['file']) {
    const omitTypename = (key: string, value: any) =>
      key === '__typename' ? undefined : value;
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename,
    );
  }
  return forward(operation).map((data) => {
    return data;
  });
});

// utility for building correct URI depending on if we are SSR or client
export const buildURI = (path: string): string => {
  const host = isSSR
    ? process.env.NEXT_PUBLIC_SPEARHEAD_BACKEND_HOST
    : process.env.NEXT_PUBLIC_SPEARHEAD_FRONTEND_HOST;
  return host + path;
};

// utility for creating an apollo client
export function createClient(path: string, links: ApolloLink[] = []) {
  const defaultOptions = isSSR ? ssrOptions : {};
  const uri = buildURI(path);
  const uploadLink = createUploadLink({
    uri: uri,
    credentials: 'include',
    defaultOptions,
  });

  links.push(cleanTypeName);
  links.push(uploadLink);

  const httpLink = ApolloLink.from(links);

  // for ssr we probably want to scope request caching
  return new ApolloClient({
    cache: new InMemoryCache(),
    link: httpLink,
    defaultOptions,
    ssrMode: isSSR,
    connectToDevTools: process.env.NEXT_PUBLIC_DEV_TOOLS === 'yes',
  });
}

export const client = createClient(PRIVATE_API);
