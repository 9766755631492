import gql from 'graphql-tag';
import {BASE_FAQ_FIELDS} from './faq';
import {BASE_FEATURE_FIELDS} from './feature';
import {BASE_TEXT_DOCUMENT_FIELDS} from './textDocument';
import {BASE_TERMS_DOCUMENT_FIELDS} from './termsDocument';

export const FULL_BRICK_CONFIG_FIELDS = gql`
  fragment FullBrickConfigFields on BrickConfigNode {
    brickId
    brickPricingId
    brickType
    isPrivate
    isDeferred
  }
`;

export const BASE_PRODUCT_FIELDS = gql`
  fragment BaseProductFields on ProductNode {
    id
    active
    name
    slug
    logoImage
    description
    descriptionShort
    createdAt
    updatedAt
    primaryColor
    invoiceEmailAutoSend
    selectedSkuOrder
    acceptsCreditCardPayment
    isStripeIntegrationEnabled
    acceptsAchPayment
    acceptsCheck
    minimumOrderFormTcv
    redirectUrl
    autoRenewalDefault
    renewalSettings {
      notificationCadence
    }
    paymentDueUponReceipt
  }
`;

export const BASE_BRICK_FIELDS = gql`
  fragment BaseBrickFields on BrickNode {
    id
    name
    usagePriceCalculation
    usageFrequency
    skuSchedule
    customUnitText
    skuType
    skuDefinition
    summary
    code
    cogs
    isOneTimePurchase
    deletedAt
  }
`;

export const BASE_BRICK_PRICING_FIELDS = gql`
  ${BASE_BRICK_FIELDS}
  fragment BaseBrickPricingFields on BrickPricingNode {
    id
    version
    createdAt
    brick {
      ...BaseBrickFields
    }
    tiers {
      currency
      tiers {
        lowerBound
        priceOneTime
        priceMonthly
        priceAnnual
        priceUsage
        pricePreCommitment
        percentage
        percentagePriceMin
      }
    }
    isOneTimePurchase
    minUnits
    maxUnits
    includedUnits
    pricingTierSystem
    priceType
    calculatePercentageOnDiscountedTotal
    percentageBaseBrickIds
  }
`;

export const BASE_TRIAL_SETTINGS_FIELDS = gql`
  fragment BaseTrialSettingsFields on TrialSettingsNode {
    defaultContractLength
    creditCardRequired
    autoConvert
  }
`;

export const BASE_PLAN_FIELDS = gql`
  ${BASE_TRIAL_SETTINGS_FIELDS}
  fragment BasePlanFields on PlanNode {
    id
    name
    code
    skuDefinition
    summary
    allowedBillingSchedule
    displayBillingType
    pricePostscript
    summary
    isPrivate
    pricePostscript
    draftTrialSettings {
      ...BaseTrialSettingsFields
    }
    publishedAt
    product {
      id
      slug
      name
      autoRenewalDefault
    }
  }
`;

export const BASE_PLAN_VERSION_FIELDS = gql`
  ${BASE_PLAN_FIELDS}
  ${BASE_BRICK_PRICING_FIELDS}
  ${FULL_BRICK_CONFIG_FIELDS}
  ${BASE_BRICK_FIELDS}
  ${BASE_TRIAL_SETTINGS_FIELDS}
  fragment BasePlanVersionFields on PlanVersionNode {
    id
    plan {
      ...BasePlanFields
    }
    planBrick {
      ...BaseBrickPricingFields
    }
    inferredPlanBrick {
      ...BaseBrickPricingFields
    }
    includedBricks {
      ...BaseBrickPricingFields
    }
    addonBricks {
      ...BaseBrickPricingFields
    }
    deferredBricks {
      ...BaseBrickFields
    }
    brickConfig {
      ...FullBrickConfigFields
    }
    trialSettings {
      ...BaseTrialSettingsFields
    }
  }
`;

export const FULL_PLAN_FIELDS = gql`
  ${BASE_PLAN_FIELDS}
  ${BASE_PLAN_VERSION_FIELDS}
  fragment FullPlanFields on PlanNode {
    ...BasePlanFields
    latestVersion {
      ...BasePlanVersionFields
    }
    featureSet {
      id
      feature {
        id
        alive
        active
        summary
        name
      }
      plan {
        id
        name
      }
      note
      showCheckMark
    }
    isPrivate
  }
`;

export const FULL_PRODUCT_FIELDS = gql`
  ${BASE_BRICK_PRICING_FIELDS}
  ${BASE_PRODUCT_FIELDS}
  ${BASE_TEXT_DOCUMENT_FIELDS}
  ${BASE_FEATURE_FIELDS}
  ${BASE_FAQ_FIELDS}
  ${FULL_PLAN_FIELDS}
  ${BASE_TERMS_DOCUMENT_FIELDS}
  fragment FullProductFields on ProductNode {
    ...BaseProductFields
    seller {
      id
      logoImage
      legalName
      name
      description
      primaryColor
      website
      terms {
        ...BaseTextDocumentFields
      }
      termsDocument {
        ...BaseTermsDocumentFields
      }
      commercialTermsDocuments {
        ...BaseTermsDocumentFields
      }
      showCommercialTermsDuringCheckout
      showMsaDuringCheckout
      stripeAccountId
      stripeIntegration {
        id
        accountId
        isEnabled
      }
      paymentDueUponReceipt
      supportedCurrencies
    }
    plans {
      ...FullPlanFields
    }
    featureSet {
      ...BaseFeatureFields
      skufeatureSet {
        id
        plan {
          id
          name
        }
        note
        showCheckMark
        alive
      }
    }
    faqSet {
      ...BaseFAQFields
    }
  }
`;
