import gql from 'graphql-tag';

export const BASE_ADDRESS_FIELDS = gql`
  fragment BaseAddressFields on AddressNode {
    id
    line1
    line2
    city
    region
    country
    zip
  }
`;
