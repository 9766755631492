import gql from 'graphql-tag';

export const BASE_TEXT_DOCUMENT_FIELDS = gql`
  fragment BaseTextDocumentFields on TextDocumentNode {
    id
    name
    text
    description
  }
`;
