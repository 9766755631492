import React, {useRef, useEffect} from 'react';

// HotKeys can occupy one of three levels.
// Global - available and effective no matter what is on screen
// Modal - available whenever registered
// Normal - available only while no Modal HoyKeys are registered

export enum HotKeyLevel {
  modal = 'MODAL',
  global = 'GLOBAL',
  normal = 'NORMAL',
}

// KeyCodes can be found here for reference:
// https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code/code_values

export interface HotKeyProps {
  registerHotKey: (
    desiredKey: string,
    modalLevel: HotKeyLevel,
    callBack: (key: string) => void,
  ) => void;
  deRegisterHotKey: (registeredKey: string, modalLevel: HotKeyLevel) => void;
}

interface KeyRegistration {
  key: string;
  callBack: (key: string) => void;
}

export const HotKeyContext = React.createContext<Partial<HotKeyProps>>({});

const keyInLevel = (
  desiredKey: string,
  keysAtLevel: KeyRegistration[],
): KeyRegistration | undefined => {
  return keysAtLevel.find(({key}) => key === desiredKey);
};

const HotKeyProvider = (props: {children: React.ReactNode}) => {
  const hotKeysRef = useRef({
    NORMAL: [],
    GLOBAL: [],
    MODAL: [],
  });

  useEffect(() => {
    const handleKeyUp = (event: KeyboardEvent) => {
      const {key} = event;
      const {GLOBAL, MODAL, NORMAL} = hotKeysRef.current;

      // No Key Commands when Input Selected UNLESS command is a Meta
      if (
        document.activeElement &&
        document.activeElement.tagName === 'INPUT'
      ) {
        // Leave Inputs on Escape globally
        if (key === 'Escape') {
          const activeElement = document.activeElement as HTMLInputElement;
          activeElement && activeElement.blur();
        }
        return;
      }

      // Start Checking registrations
      const globalRegistration = keyInLevel(key, GLOBAL);
      if (globalRegistration) {
        globalRegistration.callBack(key);
        return;
      }

      const modalRegistration = keyInLevel(key, MODAL);
      if (modalRegistration) {
        modalRegistration.callBack(key);
        return;
      }

      // If there are any Modal hotKey registrations, we don't fall through to
      // checking Normal
      if (MODAL.length > 0) {
        return;
      }

      const normalRegistration = keyInLevel(key, NORMAL);
      if (normalRegistration) {
        normalRegistration.callBack(key);
      }
    };
    document.addEventListener('keyup', handleKeyUp);

    return () => document.removeEventListener('keyup', handleKeyUp);
  }, []);

  const value: HotKeyProps = {
    registerHotKey: (
      desiredKey: string,
      hotKeyLevel: HotKeyLevel,
      callBack: (key: string) => void,
    ) => {
      const keysAtLevel = hotKeysRef.current[hotKeyLevel];
      if (desiredKey === '') {
        return;
      }
      if (keyInLevel(desiredKey, keysAtLevel)) {
        console.error(
          `Attempted to Bind key ${desiredKey} at level ${hotKeyLevel} but it already exists there. Please ensure components registering ${desiredKey} deregister on unmount`,
        );
      }
      const newRegistration = {key: desiredKey, callBack};
      hotKeysRef.current = {
        ...hotKeysRef.current,
        [hotKeyLevel]: [...keysAtLevel, newRegistration],
      };
    },
    deRegisterHotKey: (keyCodeToRemove: string, hotKeyLevel: HotKeyLevel) => {
      const keysAtLevel = hotKeysRef.current[hotKeyLevel];
      const keyRegistrationIndex = keysAtLevel.findIndex(
        ({key}) => key === keyCodeToRemove,
      );
      if (keyRegistrationIndex === -1) {
        console.error(
          `Attempted to deregister key ${keyCodeToRemove} at level ${hotKeyLevel} but it doesn't exist there.`,
        );
        return;
      }
      keysAtLevel.splice(keyRegistrationIndex, 1);
      hotKeysRef.current = {
        ...hotKeysRef.current,
        [hotKeyLevel]: keysAtLevel,
      };
      console.debug('dereg', keyCodeToRemove, hotKeyLevel);
    },
  };

  return <HotKeyContext.Provider value={value} {...props} />;
};

export default HotKeyProvider;
