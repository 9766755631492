import {useLocalStorage} from 'hooks/useLocalStorage';
import React, {useContext, useEffect, useRef, useState} from 'react';

interface Funcs {
  dismissToast: (id?: string) => void;
  dismissPersistedToast: () => void;
  showToast: (message: string | JSX.Element, type: ToastType) => void;
  pushToast: (message: string | JSX.Element, type: ToastType) => void;
}

export enum ToastType {
  'error' = 'ERROR',
  'info' = 'INFO',
  'success' = 'SUCCESS',
}

export interface Toast {
  message: string | JSX.Element;
  id: string;
  type: ToastType;
}

export interface ToastProviderProps extends Funcs {
  toastSlices: Toast[];
  persistedToastSlices: Toast[];
}

export const ToastContext = React.createContext<Partial<ToastProviderProps>>({
  toastSlices: [],
  persistedToastSlices: [],
});

const getNewId = (): string => {
  const randomId = Math.floor(Math.random() * 10000) + 1;
  return `${new Date().getDate()}-${randomId}`;
};

const ToastProvider = (props: {children: React.ReactNode}) => {
  const [toastSlices, setToastSlices] = useState<Toast[]>([]);
  const [persistedToastSlices, setPersistedToastSlices] = useLocalStorage(
    'toastSlices',
    [],
  );

  const slicesRef = useRef(toastSlices);
  const setSlicesRef = useRef(setToastSlices);
  useEffect(() => {
    slicesRef.current = toastSlices;
    setSlicesRef.current = setToastSlices;
  }, [toastSlices, setToastSlices]);

  const value = {
    toastSlices,
    persistedToastSlices,
    dismissToast: (dismissToastId?: string) => {
      const newSlices = [...slicesRef.current];
      const toastIndex = newSlices.findIndex(({id}) => id === dismissToastId);
      newSlices.splice(toastIndex, 1);
      setSlicesRef.current(newSlices);
    },
    dismissPersistedToast: () => {
      setPersistedToastSlices([]);
    },
    pushToast: (message: string | JSX.Element, toastType: ToastType) => {
      const newToast: Toast = {
        message,
        type: toastType,
        id: getNewId(),
      };
      setPersistedToastSlices([newToast, ...persistedToastSlices]);
    },
    showToast: (message: string | JSX.Element, toastType: ToastType) => {
      const newToast: Toast = {
        message,
        type: toastType,
        id: getNewId(),
      };
      setSlicesRef.current([newToast, ...slicesRef.current]);
    },
  };

  return <ToastContext.Provider value={value} {...props} />;
};

export const useToastContext = (): ToastProviderProps => {
  const toastContext = useContext(ToastContext);

  if (toastContext === undefined) {
    throw new Error(
      'Attempting to read ToastContext outside a Provider heirarchy',
    );
  }

  return toastContext as ToastProviderProps;
};

export default ToastProvider;
