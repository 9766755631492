import {Order} from 'types';
import {
  add,
  addDays,
  addMonths,
  differenceInCalendarDays,
  differenceInMonths,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfYear,
  format as dateFnsFormat,
  formatDistanceStrict,
  isAfter,
  isBefore,
  isEqual,
  parse as dateFnsParse,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfYear,
  subMonths,
  subQuarters,
} from 'date-fns';
import {format as formatTZ} from 'date-fns-tz';

interface StartEndDates {
  startDate: Date;
  endDate: Date;
}

export function parseGraphqlDatetime(s: string): Date {
  /*
   * all graphql datetimes should get parsed via this function.
   *
   */
  return new Date(s);
}

export function parseGraphqlDate(s: string): Date {
  /*
   * parse a yyyy-mm-dd string into a Date without assuming
   * any timezones.
   *
   * all graphql dates should get parsed via this function.
   */
  const parts = s.split('-');

  if (parts.length > 3 || !!s.match('T')) {
    // this doesnt look like a date so parse as a datetime
    return parseGraphqlDatetime(s);
  }

  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // 0 indexed
  const day = parseInt(parts[2], 10);

  return new Date(year, month, day);
}

export function newDate(): Date {
  /*
   *  returns a new date that can be used for start/end date
   *
   *  currently endOfDays the created date so that it consistent
   */
  return endOfDay(new Date());
}

export function getOrderNumberOfMonths(order: Order): number {
  let {endsAt, startsAt} = order;
  return getNumberOfMonths(startsAt, endsAt);
}

// we use differenceInCalendarDays here to disregard any timestamps
// e.g. Jan 3, 2024 11:00 pm - Jan 4, 2024 1:00 am is only 2 hours
// differenceInDays would evaluate this as 0 days
// but differenceInCalendarDays evaluates this as 1 day
export function getNumberOfDays(startsAt: Date, endsAt: Date): number {
  if (!endsAt || !startsAt) {
    return 0;
  }
  return differenceInCalendarDays(endsAt, startsAt);
}

export function getNumberOfMonths(startsAt: Date, endsAt: Date): number {
  if (!endsAt || !startsAt) {
    return 0;
  }
  let numMonths =
    (endsAt.getFullYear() - startsAt.getFullYear()) * 12 +
    (endsAt.getMonth() - startsAt.getMonth());
  // If we started on the first of a month,
  // we end on the last day of the previous month
  if (startsAt.getDate() === 1) {
    numMonths += 1;
  }
  return numMonths;
}

// does not care what date in the month it is or for user facing number of months
export function getLiteralDifferenceOfMonths(
  startsAt: Date,
  endsAt: Date,
): number {
  return (
    endsAt.getMonth() -
    startsAt.getMonth() +
    (endsAt.getFullYear() - startsAt.getFullYear()) * 12
  );
}

export function getUserFacingNumberOfMonthsApart(
  endDate: Date,
  startDate: Date,
): number {
  // shift the enddate one day so that the difference in months correctly works for cases like:
  // start Sun May 01 2022 07:00:00 GMT-0700 (Pacific Daylight Time)
  // end Tue Jan 31 2023 23:59:59 GMT-0800 (Pacific Standard Time)
  // we consider that 9 months, but differenceInMonths counts it as 8
  return differenceInMonths(startOfDay(addDays(endDate, 1)), startDate);
}

export function format(
  date: Date,
  format: string, // token string based on date-fns docs, e.g. 'MMM d, yyyy'
  isUppercase?: boolean,
): string {
  if (!date) {
    return '';
  }
  const output = dateFnsFormat(date, format);
  return isUppercase ? output.toUpperCase() : output;
}

export function parse(
  inputDate: string,
  format: string, // token string based on date-fns docs, e.g. 'MMM d, yyyy'
  fill?: Date, // for filling values missing from the parsed inputDate
): Date {
  return dateFnsParse(inputDate, format, fill ?? new Date());
}

export function convertToUTC(date: Date): Date {
  /*
   * utility function for converting to UTC. takes
   * the offset and applies it in minutes
   *
   * not currently being used anywhere
   */
  const offset = date.getTimezoneOffset();
  return add(date, {minutes: offset});
}

export function humanizeDateDiff(
  laterDate: Date,
  earlierDate: Date,
  abbreviateUnits?: boolean,
): String {
  let outputDate = formatDistanceStrict(laterDate, earlierDate);

  if (abbreviateUnits) {
    return outputDate
      .replace(' years', 'y')
      .replace(' year', 'y')
      .replace(' months', 'mo')
      .replace(' month', 'mo')
      .replace(' weeks', 'w')
      .replace(' week', 'w')
      .replace(' days', 'd')
      .replace(' day', 'd')
      .replace(' hours', 'h')
      .replace(' hour', 'h')
      .replace(' minutes', 'm')
      .replace(' minute', 'm')
      .replace(' seconds', 's')
      .replace(' second', 's');
  }

  return outputDate;
}

export function toISODate(d: Date): string {
  /*
   * convert a date to an ISO date string (retaining
   * the current timezone)
   */
  return format(d, 'yyyy-MM-dd');
}

interface ToDateOptions {
  isUppercase?: boolean;
  showFullMonth?: boolean;
}
export function toDate(
  d: Date,
  options: ToDateOptions = {
    isUppercase: false,
    showFullMonth: false,
  },
): string {
  const {isUppercase, showFullMonth} = options;

  if (!d) {
    return '';
  }

  const outputFormat = showFullMonth ? 'MMMM dd, yyyy' : 'MMM dd, yyyy';

  return format(d, outputFormat, isUppercase);
}

export function toFullMonthDate(d: Date): string {
  return toDate(d, {showFullMonth: true});
}

export function toShortDate(d: Date): string {
  if (!d) {
    return '';
  }
  return format(d, 'MM/dd/yy').toUpperCase();
}

export function toYearLessTimestamp(d: Date): string {
  if (!d) {
    return '';
  }

  return formatTZ(d, 'MMM dd, hh:mm aa z');
}

export function toLongDate(
  d: Date,
  upperCase: boolean = false,
  options = null,
): string {
  if (!d) {
    return '';
  }

  const f = formatTZ(d, 'MMM dd, yyyy HH:mm:ss zzz', options);

  if (upperCase) {
    return f.toUpperCase();
  }
  return f;
}

export function toLongDateNoSeconds(
  d: Date,
  upperCase: boolean = false,
  options = null,
): string {
  if (!d) {
    return '';
  }

  const f = formatTZ(d, 'MMM dd, yyyy hh:mm aa zzz', options);

  if (upperCase) {
    return f.toUpperCase();
  }
  return f;
}

export function toShortDateWithFullYear(d: Date, isUppercase = false): string {
  if (!d) {
    return '';
  }
  return format(d, 'MM/dd/yyyy', isUppercase);
}
/**
 * Converts a given base date to UTC with optional adjustments, to avoid TZ issues as GQL relies on UTC.
 *
 * @param baseDate - The base date to be adjusted.
 * @param endOfTheDay - If true, sets the time to the end of the day.
 * @param fiscalYearStartMonth - Optional adjustment for the month in fiscal year scenarios.
 * @returns The adjusted UTC date.
 */

export function getDateInUTC(
  baseDate: Date,
  endOfTheDay: boolean = false,
  fiscalYearStartMonth?: number,
): Date {
  const hours = endOfTheDay ? 23 : 0;
  const minutes = endOfTheDay ? 59 : 0;
  const seconds = endOfTheDay ? 59 : 0;
  const milliseconds = endOfTheDay ? 999 : 0;

  // Adjust the month if a fiscal year start month is provided
  const adjustedMonth = fiscalYearStartMonth
    ? baseDate.getMonth() + fiscalYearStartMonth - 1
    : baseDate.getMonth();

  return new Date(
    Date.UTC(
      baseDate.getFullYear(),
      adjustedMonth,
      baseDate.getDate(),
      hours,
      minutes,
      seconds,
      milliseconds,
    ),
  );
}

/**
 * Retrieves the UTC start and end dates for the last month.
 *
 * @returns Object with startDate and endDate in UTC.
 */
export function getLastMonthDatesInUTC(): StartEndDates {
  const baseEndDate = endOfMonth(subMonths(new Date(), 1));
  const endDate = getDateInUTC(baseEndDate, true);

  const baseStartDate = startOfMonth(subMonths(new Date(), 1));
  const startDate = getDateInUTC(baseStartDate, false);
  return {startDate, endDate};
}

/**
 * Retrieves the UTC start and end dates for the last quarter.
 *
 * @param fiscalYearStartMonth - The starting month of the fiscal year.
 * @returns Object with startDate and endDate in UTC.
 */
export function getLastQuarterDatesInUTC(
  fiscalYearStartMonth: number,
): StartEndDates {
  const baseStartDate = startOfQuarter(subQuarters(new Date(), 1));
  const startDate = getDateInUTC(baseStartDate, false, fiscalYearStartMonth);

  const baseEndDate = endOfQuarter(subQuarters(new Date(), 1));
  const endDate = getDateInUTC(baseEndDate, true, fiscalYearStartMonth);
  return {startDate, endDate};
}

/**
 * Retrieves the UTC start and end dates for the year to date.
 *
 * @returns Object with startDate and endDate in UTC.
 */
export function getYearToDateInUTC(): StartEndDates {
  const baseStartDate = startOfDay(startOfYear(new Date()));
  const startDate = getDateInUTC(baseStartDate, false);

  const baseEndDate = new Date();
  const endDate = getDateInUTC(baseEndDate, true);
  return {startDate, endDate};
}

/**
 * Retrieves the UTC start and end dates for the year given calendar year
 *
 * @returns Object with startDate and endDate in UTC.
 */
export function getFullYearDateRange(year: string): StartEndDates {
  const yearDate = new Date(year);

  const baseStartDate = startOfYear(yearDate);
  const startDate = getDateInUTC(baseStartDate, false);

  const baseEndDate = endOfYear(yearDate);
  const endDate = getDateInUTC(baseEndDate, true);

  return {startDate, endDate};
}

/**
 * Retrieves the UTC start and end dates for the current calendar year
 *
 * @returns Object with startDate and endDate in UTC.
 */
export function getFullYearDateRangeForCurrentYear(): StartEndDates {
  const currentYear = getCurrentYear();

  return getFullYearDateRange(currentYear);
}

export function getCurrentYear() {
  return format(new Date(), 'yyyy');
}

/**
 * Retrieves the local timezone start and end dates for a full fiscal year
 * starting with the month and year provided.
 *
 * @param fiscalYearStartMonth - 0-based month number which represents the start month
 * of the fiscal year
 *
 * @param targetYear - optional year value. Uses current calendar year if omited
 *
 * @returns Object with startDate and endDate in local timezone.
 */
export function getFiscalYearDateRange(
  fiscalYearStartMonth: number,
  targetYear?: string,
): StartEndDates {
  const yearToUse = Number(
    targetYear ?? getCurrentActiveFiscalYear(fiscalYearStartMonth),
  );

  const baseStartDate = startOfMonth(new Date(yearToUse, fiscalYearStartMonth));

  const baseEndDate = endOfMonth(addMonths(baseStartDate, 11));

  return {
    startDate: baseStartDate,
    endDate: baseEndDate,
  };
}

/**
 * Retrieves the UTC timezone start and end dates for a full fiscal year
 * starting with the month and year provided.
 *
 * @param fiscalYearStartMonth - 0-based month number which represents the start month
 * of the fiscal year
 *
 * @param targetYear - optional year value. Uses current calendar year if omited
 *
 * @returns Object with startDate and endDate in UTC.
 */
export function getFiscalYearDateRangeInUTC(
  fiscalYearStartMonth: number,
  targetYear?: string,
): StartEndDates {
  const {startDate, endDate} = getFiscalYearDateRange(
    fiscalYearStartMonth,
    targetYear,
  );

  return getDateRangeInUTC({
    startDate: startDate,
    endDate: endDate,
  });
}

/**
 * Retrieves the year value of the current fiscal year.
 *
 * @param fiscalYearStartMonth - 0-based month number which represents the start month
 * of the fiscal year
 */
export function getCurrentActiveFiscalYear(
  fiscalYearStartMonth: number,
): string {
  const currentCalendarYear = Number(getCurrentYear());
  const startOfFiscalYearInCurrentCalendarYear = startOfMonth(
    new Date(currentCalendarYear, fiscalYearStartMonth),
  );

  const currentMonth = startOfMonth(new Date());

  const useCurrentCalendarYearInFiscalYear: boolean =
    currentMonth >= startOfFiscalYearInCurrentCalendarYear;

  // if calendar year hasn't ended yet for last year,
  // we should use the last calendar year as starting year
  // for the range
  const targetYear = useCurrentCalendarYearInFiscalYear
    ? currentCalendarYear
    : currentCalendarYear - 1;

  return targetYear.toString();
}

export function formatMonthYear(date: Date) {
  return format(date, 'LLL yyyy');
}

export function getDateRangeInUTC({startDate, endDate}: StartEndDates) {
  return {
    startDate: getDateInUTC(startDate),
    endDate: getDateInUTC(endDate, true),
  };
}

/**
 * Determines if a given date is equal to
 * or is after another date.
 */
export const isOnOrAfter = (date: Date, comparisonDate: Date) => {
  return isEqual(date, comparisonDate) || isAfter(date, comparisonDate);
};

/**
 * Determines if a given date is equal to
 * or is before another date.
 */
export const isOnOrBefore = (date: Date, comparisonDate: Date) => {
  return isEqual(date, comparisonDate) || isBefore(date, comparisonDate);
};

/**
 * generates copyright text for the in-app footer.
 */
export const getCopyrightText = (): string => {
  const year = format(new Date(), 'yyyy');
  return `Salesbricks © ${year}. All rights reserved.`;
};
